.form-submission-view {
  .form-group.has-feedback.formio-component.formio-component-button.formio-component-submit.form-group {
    display: none;
  }
}

.signature-pad-body {
  width: auto;
  height: auto;
  background-color: #f5f5eb;
}
